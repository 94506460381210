<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				<span>{{ $t('Storage.nav.SKU_inventories') }}</span>
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->
		
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span>SKU<span>{{$t('i18nn_2fc069db0549ff40')}}</span></span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">
					
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList"> -->
					
				<!-- </ul> -->
				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<!-- <li>
						<span>SKU</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku" :clearable="true" style="width: 200px;"></el-input>
						
					</li> -->
					
					<li>
						<span>SKU</span>
						<el-input type="text" v-model="filterData.goodsSku" size="small" clearable
							@keyup.enter.native="initData()" maxlength="100" :placeholder="$t('hytxs0000001')"
							style="width: 220px;" />
						<el-tooltip class="" effect="dark" :content="'多SKU搜索'"
							placement="top">
							<el-button  icon="el-icon-s-operation" size="small" type="success" plain
								@click="openSkuInputAction()"></el-button>
						</el-tooltip>
					</li>
					
					<li>
						<span>入库时间</span>
						<el-date-picker v-model="filterData.inWhDateArr" type="datetimerange" align="right"
							unlink-panels range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd HH:mm:ss"
							format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" size="small" @change="initData()"
							:picker-options="pickerOptions" style="width:360px;"></el-date-picker>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData"></el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
			<!-- 多SKU搜索显示 -->
			<div class="" style="padding: 0 10px 5px 10px;"
				v-if="filterData.goodsSkuList && filterData.goodsSkuList.length > 0">
				<ul class="filterConList" style="text-align: left;">
					<el-button icon="el-icon-close" size="small" type="success"
						@click="clearSkuList()">
						<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
						(<span>多SKU搜索</span>)
						(<span>共<span>{{filterData.goodsSkuList.length}}</span></span>)
					</el-button>
					<li v-for="(item, index) in filterData.goodsSkuList" :key="index">
						<el-tag size="small" effect="plain">{{ item }}</el-tag>
					</li>
				</ul>
			</div>
		</div>
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
        <el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{$t('Storage.skuInfo.Add_SKU')}}</el-button>
        <el-button type="warning" size="small" icon="el-icon-upload">{{$t('Storage.skuInfo.Import_SKU')}}</el-button>
    </div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" 
			size="small" :max-height="$store.state.frameConHeightWh">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('7b1c2b1adc920d9c')"></el-table-column>
				
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')" show-overflow-tooltip>
				</el-table-column> -->
				
				<el-table-column prop="goodsSku" label="SKU"></el-table-column>
				<el-table-column prop="stock" :label="$t('i18nn_de53ab10322cd38a')"></el-table-column>
				<el-table-column prop="zoneWhNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
				<el-table-column prop="place" :label="$t('i18nn_3a6eb04ef8f3327b')"></el-table-column>
				<el-table-column prop="inWhDate" :label="$t('i18nn_9eaa906ebc446062')">
				</el-table-column>
				
				<el-table-column prop="inWhSourceName" :label="$t('i18nn_f1c73718e1c8fcb7')">
				</el-table-column>
				
				<!-- <el-table-column prop="isCalcFee" :label="'isCalcFee'"></el-table-column> -->
				
				<el-table-column prop="receiptNo" :label="$t('i18nn_1829e02aab962bd5')"></el-table-column>
				<el-table-column prop="relationNo" :label="$t('i18nn_9168144190f66f5d')"></el-table-column>
				<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')"></el-table-column>
				
				<!-- <el-table-column prop="takeStockTypeName" :label="'takeStockTypeName'"></el-table-column> -->
				<el-table-column prop="thridStockInfo" :label="$t('i18nn_c62b0d9279f5e7cd')"></el-table-column>
				
				
				<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				<el-table-column prop="createTime" :label="$t('i18nn_d99d790ec4383bfb')" width="160">
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		
		<!-- sku输入框 -->
		<el-dialog :title="'SKU输入'" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogSkuInputVisible" width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.goodsSkuListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="'一行输入一个SKU'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSkuInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureSkuInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog>
		

		<!--查看明细-->
		<!-- <el-dialog :title="$t('Storage.skuInfo.SKU_detail')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="10px"> -->
		<!-- <el-drawer :wrapperClosable="false" :title="$t('Storage.skuInfo.SKU_detail')" append-to-body :visible.sync="dialogShowDet" :direction="'rtl'" size="1200px">
			<div style="">
				<el-table :data="DetData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="goodsSku" label="SKU"></el-table-column>
					
					<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')">
						<template slot-scope="scope">
							<div>
								<div>{{scope.row.goodsName}}</div>
								<div v-if="scope.row.goodsName!=scope.row.goodsNameEn">{{scope.row.goodsNameEn}}</div>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="goodsLength"
						:label="$t('Storage.skuInfo.size')+'('+$t('Storage.skuInfo.length_width_height')+')'">
						<template slot-scope="scope">
							<div>
								<span>{{scope.row.goodsLength}}</span>*
								<span>{{scope.row.goodsWidth}}</span>*
								<span>{{scope.row.goodsHeight}}</span>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>
					
					<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>
					
					<el-table-column prop="isBatteryName" :label="$t('i18nn_6d8f9b0c398319ff')">
						<template slot-scope="scope">
							<div v-if="scope.row.isBattery">
								<el-tag type="info" v-if="'0' == scope.row.isBattery">{{ scope.row.isBatteryName }}</el-tag>
								<el-tag type="warning" v-else-if="'1' == scope.row.isBattery">{{ scope.row.isBatteryName }}</el-tag>
								<el-tag type="" v-else>{{ scope.row.isBatteryName }}</el-tag>
							</div>
						</template>
					</el-table-column>
					
					
					<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
					
					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>
					
					<el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>
				</el-table>
			</div>
		</el-drawer> -->
		<!-- </el-dialog> -->

		<!--查看库位明细-->
		<!-- <el-dialog :title="$t('i18nn_036240f3e205cce2')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSkuPlace" width="1000px" top="10px"> -->
		<!-- <el-drawer :wrapperClosable="false" :title="$t('i18nn_036240f3e205cce2')" append-to-body :visible.sync="dialogSkuPlace" :direction="'rtl'" size="1000px">
			<div style="" v-loading="loading_det">
				<el-table :data="SkuPlaceData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>

					<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
					<el-table-column prop="place" :label="$t('i18nn_3a6eb04ef8f3327b')"></el-table-column>

					<el-table-column prop="stock" :label="$t('i18nn_de53ab10322cd38a')"></el-table-column>

					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-drawer> -->
		<!-- </el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhStockDetPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	import {
			FormatTableSort2
		} from '@/utils/utils.js';
		
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	// props: {
	// 	// mobile:"",
	// 	isSel: {
	// 		default: function() {
	// 			return false;
	// 		},
	// 		type: Boolean
	// 	},
	// 	status: {
	// 		default: function() {
	// 			return '';
	// 		},
	// 		type: String
	// 	}
	// },
	components: {
		SelAgentUser,
		whExcelCustom,
		whNoSelect
	},
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: "",
			excelOption: {},
			
			pickerOptions: this.$PickerDate.pickerOptions_1(),
			// activeName: 'second',
			// isShowFrom:false,
			// pickerOptions1: {
			//     disabledDate(time) {
			//       return time.getTime() > Date.now();
			//     },
			//     shortcuts: [{
			//       text: this.$t('i18nn_0200bd47bb4bb83d'),
			//       onClick(picker) {
			//         picker.$emit('pick', new Date());
			//       }
			//     }, {
			//       text: this.$t('i18nn_84b73bfc6dada445'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24);
			//         picker.$emit('pick', date);
			//       }
			//     }, {
			//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
			//         picker.$emit('pick', date);
			//       }
			//     }]
			//   },
			// dialogShowDet: false,
			// DetData: [],

			// dialogSkuPlace: false,
			// loading_det: false,
			// SkuPlaceData: [],
			//sku输入弹窗
			dialogSkuInputVisible: false,
			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading: false,
			// form: {
			// 	id: null, //"数据ID",
			// 	goodsSubjectId: '', //this.$t('i18nn_268e50d838264ca8'),
			// 	goodsSku: '', //"SKU编码",
			// 	goodsName: '', //"SKU名称",
			// 	goodsNameEn: '', //"SKU英文标题",
			// 	goodsImg: '', //"SKU图片",
			// 	declarePrice: '', //this.$t('i18nn_9db48ed0e8ec64a6'),
			// 	goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
			// 	sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			// 	goodsLength: '', //this.$t('i18nn_0e60303b30d5d4b4'),
			// 	goodsWidth: '', //this.$t('i18nn_6cd31871f8528dd5'),
			// 	goodsHeight: '', //this.$t('i18nn_93c9ea4a0e52c641'),
			// 	safeStock: '' //this.$t('i18nn_004617dc47191cb8')
			// },

			// formRules: {
			// 	goodsSubjectId: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsSku: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsImg: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	sizeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	safeStock: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
			// },
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				wh_weight_unit: [],
				wh_vol_unit: [],
				wh_goods_fee_type: [],
				// wh_no: []
				// statusList: [
				//   {
				//     value: '',
				//     label: this.$t('i18nn_16853bda54120bf1')
				//   },
				//   {
				//     value: '1',
				//     label: this.$t('i18nn_fdd8426d7b8869c5')
				//   },
				//   {
				//     value: '2',
				//     label: this.$t('i18nn_619d7e563f879811')
				//   },
				//   {
				//     value: '3',
				//     label: this.$t('i18nn_1dfb3a065d3ffe39')
				//   }
				// ]
			},
			//查询，排序方式
			filterData: {
				orderBy: '', //排序字段
				sortAsc: '', //desc降序，asc升序
				// "accountPeriod":"",
				// "billNo":""
				// status: '',
				// putWhNo: '',
				agentUser: '',
				whNo: '',
				// goodsSku: '',
				goodsSku: '',
				goodsSkuList: [],
				goodsSkuListStr: '',
				// hashCode: '',
				goodsName: '',
				goodsNameEn: '',
				keyword: '',
				inWhDateArr: [],
				// declareNameCh: '',
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();

		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		// showDetList($event, row) {
		// 	$event.stopPropagation();
		// 	this.dialogShowDet = true;
		// 	this.DetData = [row.mySku];
		// },
		changeAgentUser(val) {
			this.filterData.agentUser = val;
			this.initData();
		},
		//库位查询
		// showSkuLocation($event, row) {
		// 	$event.stopPropagation();
		// 	this.dialogSkuPlace = true;
		// 	this.SkuPlaceData = [];
		// 	this.getSkuLocationData(row.goodsSku, row.whNo, this.filterData.agentUser);
		// 	// this.dialogShowDet = true;
		// 	// this.DetData = [row.mySku];
		// },

		//导出 excel
		//导出 excel
		exportExcel() {
		
			let columns = [
				// {
				// 	title: this.$t('i18nn_29991afa9781d554'),
				// 	key: 'cusName'
				// },
				{
					title: 'SKU',
					key: 'goodsSku'
				},
				{
					title: this.$t('i18nn_de53ab10322cd38a'),
					key: 'stock'
				},
				{
					title: this.$t('i18nn_c944a6686d996ab3'),
					key: 'zoneWhNo'
				},
				{
					title: this.$t('i18nn_3a6eb04ef8f3327b'),
					key: 'place'
				},
				{
					title: this.$t('i18nn_9eaa906ebc446062'),
					key: 'inWhDate'
				},
				{
					title: this.$t('i18nn_f1c73718e1c8fcb7'),
					key: 'inWhSourceName'
				},
				{
					title: this.$t('i18nn_1829e02aab962bd5'),
					key: 'receiptNo'
				},
				{
					title: this.$t('i18nn_9168144190f66f5d'),
					key: 'relationNo'
				},
				{
					title: this.$t('i18nn_6cdece641436d7ab'),
					key: 'statusName'
				},
				{
					title: this.$t('i18nn_c62b0d9279f5e7cd'),
					key: 'thridStockInfo'
				},
				{
					title: this.$t('Storage.skuInfo.explain'),
					key: 'remark'
				},
				{
					title: this.$t('i18nn_d99d790ec4383bfb'),
					key: 'createTime'
				},
			];
			let Data = this.tableData;
		
			// if (!this.filterData.userId) {
			// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 	return;
			// }
			// if (!this.filterData.daterange || this.filterData.daterange.length<2) {
			// 	this.$message.warning(this.$t('i18nn_91e192ea5925de92'));
			// 	return;
			// }
		
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'WhStockList';
			this.excelOption = {
				height: 20
			};
			this.excelOpenTime = new Date().getTime();
			// excelUtilsNew.exportExcel(columns, Data, 'WhCostStatistics',{height:20});
		},
		
		//打开的输入框
		openSkuInputAction() {
			// this.filterData.sendNoListStr = "";
			this.dialogSkuInputVisible = true;
		},
		//确定的输入框
		sureSkuInputAction() {
			this.dialogSkuInputVisible = false;
			this.filterData.goodsSku = '';
			let goodsSkuList = [];
			if (this.filterData.goodsSkuListStr) {
				goodsSkuList = this.filterData.goodsSkuListStr.split('\n').filter(function(s) {
					return s && s.trim();
				});
			}
			this.filterData.goodsSkuList = goodsSkuList;
			this.initData();
		},
		//清除搜索的值
		clearSkuList() {
			this.filterData.goodsSkuListStr = '';
			this.filterData.goodsSkuList = [];
			this.initData();
		},
		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		// openDioalog(formParm) {
		//   // console.log(formParm);
		//   this.dialogFormVisible = true;
		//   let form = Object.assign({}, formParm);
		//   console.log('form',form);
		//   // // 重置
		//   this.resetForm('form');
		//   if (null === formParm) { //新增

		//     this.form.weightUnit = '1';
		//     this.form.volumeUnit = '1';
		//     this.form.whFeeType = '1';

		//     this.dialogFormStatus = 0;
		//     // form.subUserId = null;
		//     // form.userSubUserId = null;
		//     // form.state = true;
		//     //浅拷贝、对象属性的合并
		//     this.form = form;

		//   } else { //修改
		//     this.dialogFormStatus = 1;
		//     // form.state = form.state === '0' ? true : false;

		//     //浅拷贝、对象属性的合并
		//     this.form = form;

		//   }
		//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
		//   //       type: 'warning',
		//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//   //     });
		// },
		//打开编辑
		// openEdit(event, row, index) {
		//   event.stopPropagation();
		//   this.openDioalog(row, this.$t('FormMsg.Edit'));
		// },

		//删除
		// delAction(event, row) {
		//   this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
		//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   })
		//     .then(() => {
		//       // this.$message({
		//       //   type: 'success',
		//       //   message: '删除成功!'
		//       // });
		//       this.delDataAction(event, row);
		//     })
		//     .catch(() => {
		//       // this.$message({
		//       //   type: 'info',
		//       //   message: this.$t('i18nn_2e58cb9b52e2a214')
		//       // });
		//     });
		// },
		// //删除
		// delDataAction(event, row) {
		//   event.stopPropagation();
		//   console.log('delDataAction', row);
		//   // let parm = [];

		// //   if (!!row) {
		// //     //单条
		// //     parm = [row.id];
		// //   } else {
		// //     //多条
		// // //     let dataList = this.multipleSelection;
		// // //     if (dataList.length < 1) {
		// // //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
		// // //       return;
		// // //     }

		// // //     let dataListParm = dataList.map(v => v.id);
		// // //     parm = dataListParm;
		// //     // console.log('dataListParm', dataListParm);
		// //     // let dataParm = {
		// //     //   ids: dataListParm
		// //     // };
		// //   }

		//   this.postData(this.$urlConfig.WhMyGoodsSkuDel+'/'+row.id, {},'delete');
		// },

		// hyUpLoadImg1: function(childValue) {
		// 	// childValue就是子组件传过来的值
		// 	this.form.goodsImg = childValue;
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		// 	event.stopPropagation();
		// 	console.log('openBigImg', imgsrc);
		// 	this.dialogImgVisible = true;
		// 	this.imgUrlBigShow = imgsrc;
		// },

		//点击状态为非出账弹出窗
		// noConfirm(){
		//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		// },
		//提交信息
		// submitForm(formName) {

		//   this.$refs[formName].validate((valid) => {

		//     if (valid) {
		//       let formData = Object.assign({}, this.form);
		//       //浅拷贝、对象属性的合并
		//       if (0 === this.dialogFormStatus) {

		//         formData.id = null;
		//         formData.userId = this.UserInfo.id;

		//         this.postData(this.$urlConfig.WhMyGoodsSkuUpdate, formData);

		//       } else {

		//         formData.userId = this.UserInfo.id;
		//         this.postData(this.$urlConfig.WhMyGoodsSkuUpdate, formData);
		//       }

		//     } else {
		//       console.log('error submit!!');
		//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		//       return false;
		//     }
		//   });
		// },
		// //重置输入框
		// resetForm(formName) {
		//   console.log(formName);
		//   // console.log(this.$refs[formName]);

		//   if (this.$refs[formName]) {
		//     this.$refs[formName].resetFields();

		//   } else {
		//     console.log('this.$refs[formName]',this.$refs[formName]);
		//   }
		// },

		//提交信息
		// postData(url, formData,type) {
		//   // let _this = this;
		//   this.loading = true;
		//   let HttpType = {};
		//   if('delete'==type){
		//     HttpType = this.$http.delete(url, formData);
		//   } else {
		//     HttpType = this.$http.put(url, formData);
		//   }
		//   // formData.state = formData.state ? '0' : '1';
		//   HttpType
		//     .then(({ data }) => {
		//       console.log(this.$t('tips.submitSuccess'));
		//       console.log(data);
		//       
		//       this.loading = false;
		//       if (200 == data.code) {
		//         this.dialogFormVisible = false;
		//         this.getPageData();
		//         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
		//           type: 'success',
		//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//         });
		//       } else {
		//         // if (!data.msg) {
		//         //   data.msg = this.$t('tips.submitError');
		//         // }
		//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//           type: 'warning',
		//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//         });
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log(this.$t("tips.submitError"));
		//       this.loading = false;
		//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		//     });
		// },
		//查询数据
		// serPageData() {
		// 	this.pagination.current_page = 1;
		// 	this.getPageData();
		// },
		
		//排序改变
		// sortChange({
		// 	column,
		// 	prop,
		// 	order
		// }) {
		// 	console.log('sortChange', {
		// 		column,
		// 		prop,
		// 		order
		// 	});
		// 	let sortData = FormatTableSort2({
		// 		column,
		// 		prop,
		// 		order
		// 	});
		// 	this.filterData.orderBy = sortData.orderBy;
		// 	this.filterData.sortAsc = sortData.sortAsc;
		// 	this.getPageData();
		// },
		//分页的筛选项数据
		pageFilterData() {
			
			let goodsSkuList = [];
			if (this.filterData.goodsSkuListStr) {
				goodsSkuList = this.filterData.goodsSkuList;
			} else if (this.filterData.goodsSku) {
				goodsSkuList = [this.filterData.goodsSku];
			}
			
			let inWhDateArr = this.filterData.inWhDateArr;
			let inWhDateStart = '';
			let inWhDateEnd = '';
			if (inWhDateArr && inWhDateArr.length == 2) {
				inWhDateStart = inWhDateArr[0];
				inWhDateEnd = inWhDateArr[1];
			}
			
			return {
				// "sortAsc": this.filterData.sortAsc,
				// "orderBy": this.filterData.orderBy,
				proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				// status: this.filterData.status ? this.filterData.status : null,
				// putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
				zoneWhNo: this.filterData.whNo ? this.filterData.whNo : null,
				// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
				// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
				// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
				// goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
				keyword: this.filterData.keyword ? this.filterData.keyword : null,
				
				inWhDateStart: inWhDateStart ? inWhDateStart : null,
				inWhDateEnd: inWhDateEnd ? inWhDateEnd : null,
				
				goodsSkuList: goodsSkuList.length > 0 ? goodsSkuList : null,
				
				// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
			};
		},
		//请求分页数据
		getPageData() {
			let filterData = Object.assign(
				{
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size //当前页显示数目
				},
				this.pageFilterData()
			);
			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhStockDetPageList, filterData)
				.then(({ data }) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		//库位查询
		// getSkuLocationData(sku, whNo, agentUser) {
		// 	// let _this = this;
		// 	this.loading_det = true;
		// 	this.$http
		// 		.put(this.$urlConfig.WhMyGoodsSkuPlace, {
		// 			goodsSku: sku,
		// 			whNo: whNo,
		// 			proxyUserId: agentUser ? agentUser : null
		// 		})
		// 		.then(({ data }) => {
		// 			this.loading_det = false;
		// 			console.log('分页，请求成功');
		// 			console.log(data);
		// 			if (data.data) {
		// 				this.SkuPlaceData = data.data.skuStockInfo;
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('分页，请求失败');
		// 			this.loading_det = false;
		// 		});
		// },
		//操作
		// sureBillAction(row,msg,state) {
		//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
		//   console.log(row);
		//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
		//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   }).then(() => {
		//     //确认
		//     let dataParm = {
		//       ids:[row.id],
		//       state:state,
		//     }
		//     this.updatePageData(dataParm, msg);
		//   }).catch(() => {

		//   });

		// },
		//更新特定数据,
		// updatePageData(dataParm, msg) {
		//   // let _this = this;
		//   this.loading_load = true;
		//   //浅拷贝、对象属性的合并
		//   let dataParmAss = Object.assign({}, dataParm);
		//   // dataParmAss.isDefault = 1;
		//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
		//     .then(({ data }) => {
		//       console.log(msg + "特定数据，请求成功");
		//       console.log(data);
		//       if (200 == data.code) {
		//         this.$message.success(msg + '成功！');
		//         this.getPageData();
		//       } else {
		//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log("更新特定数据，请求失败");
		//       this.loading_load = false;
		//       this.$message.warning('' + msg + '失败,请重试！');
		//     });
		// },
		//选择数据后回调
		// selRow(event, row) {
		// 	event.stopPropagation();
		// 	this.$emit('selectRow', row);
		// },
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 				this.selectOption.wh_no = data.data['wh_no'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
</style>
